import React, { useContext, useState } from "react"
import HeaderLogo from "../../components/modules/HeaderLogo"
import { css } from "@emotion/react"
import SnsList from "../../components/modules/SnsList"
import StoreContext from "libs/context/StoreContext"
import * as Modules from "../modules"
import * as Action from "libs/redux/action"
import * as Atoms from "../atoms"

import { useDispatch } from "react-redux"
import * as Libs from "libs"
const headerCss = () =>
  css`
    position: fixed;
    z-index: 100;
    top: 0px;
    transition: transform 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
    width: 100vw;
    box-sizing: border-box;
    height: 126px;
    transform: translateY(0px);
    ${Libs.Styles.Mq.lessPab} {
      height: 103px;
    }
    .init-loaded:not(.on-scrollinit):not(.on-scrolldown):not(.on-scrollup) & {
    }

    .on-scrollinit:not(.on-story) & {
      background-color: #fff;
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-85px);
      }
      ${Libs.Styles.Mq.lessPab} {
        /*top: -59px;*/
        transform: translateY(-59px);
      }
    }

    .on-story & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-85px);
      }

      ${Libs.Styles.Mq.lessPab} {
        transform: translateY(-105px);
      }
    }
    .now-transition & {
      /*top: -200px;*/
    }
  `
const h1 = () => css`
  position: absolute;

  left: 50px;
  ${Libs.Styles.Mq.large} {
    top: 40px;
  }
  ${Libs.Styles.Mq.pc} {
    top: 40px;
  }
  ${Libs.Styles.Mq.tb} {
    top: 40px;
    left: 25px;
  }
  ${Libs.Styles.Mq.lessPab} {
    top: 26px;
    left: 25px;
  }
`
const snslistCss = () => css`
  position: absolute;
  right: 140px;
  top: 40px;
  ${Libs.Styles.Mq.pc} {
    right: 132px;
  }
  ${Libs.Styles.Mq.tb} {
    right: 105px;
  }
  ${Libs.Styles.Mq.lessPab} {
    display: none;
  }
`
const langCss = () => css`
  position: absolute;
  right: 84px;

  width: 40px;
  ${Libs.Styles.Mq.large} {
    top: 40px;
  }
  ${Libs.Styles.Mq.pc} {
    top: 42px;
  }
  ${Libs.Styles.Mq.tb} {
    right: 58px;
    top: 42px;
  }
  ${Libs.Styles.Mq.lessPab} {
    right: 50px;
    top: 17px;
  }
`
const bacCss = () => css`
  position: absolute;
  right: 50px;
  top: 40px;
  ${Libs.Styles.Mq.tb} {
    right: 25px;
  }
  ${Libs.Styles.Mq.lessPab} {
    right: 20px;
    top: 17px;
  }
`
const Header = props => {
  const { restoreCheckout } = useContext(StoreContext)
  const langKey = Libs.Common.Func.getLangKey()
  const [lang, setLang] = useState(langKey)
  const dispatch = useDispatch()

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  const clickHandler = (event, id) => {
    const key = event.currentTarget.dataset.key
    let langInfo = Libs.Common.Func.getLangInfo()
    const langKey = Libs.Common.Func.getLangKey()

    if (langKey === key) return

    Libs.Common.Config.setCurrentLang(key)

    setLang(key)

    restoreCheckout()

    dispatch(Action.LangChanged(key))
  }
  const langInfo = Libs.Common.Func.getLangInfo()

  return (
    <header css={headerCss}>
      <h1 css={h1}>
        <HeaderLogo />
      </h1>
      <Modules.GlobalNavigation />
      {/*<div css={menuiconCss}>
          <HamburgerMenu />
        </div>*/}
      <div css={snslistCss}>
        <SnsList />
      </div>
      <Libs.Atoms.IconBag css={bacCss} />
      <Modules.Breadcrumb />
      <Modules.TagFilter />
      <Atoms.Dropdown
        css={langCss}
        active={lang}
        items={[
          {
            text: "JA",
            slug: "ja",
            url: langInfo[0].link,
            onClick: clickHandler,
          },
          {
            text: "EN",
            slug: "en",
            url: langInfo[1] ? langInfo[1].link : "/",
            onClick: clickHandler,
          },
        ]}
        trigerLink={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
      />
    </header>
  )
}
export default Header
