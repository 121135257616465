import { useStaticQuery, graphql } from "gatsby"

export const useEnJournalCafeDataEn = () => {
  const { allContentfulContentJournal } = useStaticQuery(
    graphql`
      query journalCafeListEnQuery {
        allContentfulContentJournal(
          limit: 4
          filter: {
            node_locale: { eq: "en" }
            tags: { elemMatch: { slug: { eq: "howlt-coffee" } } }
          }
        ) {
          edges {
            node {
              slug
              title
              name
              date
              category {
                slug
                name
              }
              updatedAt(formatString: "MMMM DD, YYYY, h:mm:ss")

              node_locale
              thumbnail {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
              }
              tags {
                name
                slug
                group
              }
            }
          }
        }
      }
    `
  )
  return allContentfulContentJournal.edges
}
