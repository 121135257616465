import { useStaticQuery, graphql } from "gatsby";

export const AllInstaNode = () => {
	const { allInstaNode } = useStaticQuery(
		graphql`
			query allInstaNodeQuery {
				allInstaNode(sort: { order: DESC, fields: timestamp }, limit: 4) {
					edges {
						node {
							id
							likes
							localFile {
								name
								childImageSharp {
									fluid(maxWidth: 400, quality: 80) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		`
	);
	return allInstaNode.edges;
};
