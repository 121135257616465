import React, { PureComponent } from "react";
import { elementHoc } from "libs/atoms/ElementHoc";

import { css } from "@emotion/react";
import * as Libs from "libs";
export class SnsListHc extends PureComponent {
	render() {
		let {
			heightPc = 25,
			heightSp = 25,
			className,
			forwardedref,
			children,
			data,

			...rest
		} = this.props;

		const snslistCss = (props) => css`
			display: flex;
			justify-content: space-between;
			> * {
				&:first-child {
					margin-left: 0px;
				}
			}
		`;
		const stylesObj = {};
		return (
			<ul
				ref={forwardedref}
				css={[data.func(stylesObj, data.instance)(), snslistCss()]}
				className={`${className ? className : ""} `}
				{...rest}
			>
				<li>
					<Libs.Atoms.Icon
						type="google"
						heightPc={heightPc}
						heightSp={heightSp}
						url="https://g.page/howltcoffee/"
					/>
				</li>
				<li>
					<Libs.Atoms.Icon
						type="insta"
						heightPc={heightPc}
						heightSp={heightSp}
						url="https://www.instagram.com/howltcoffee/"
					/>
				</li>
				<li>
					<Libs.Atoms.Icon
						type="fb"
						heightPc={heightPc}
						heightSp={heightSp}
						url="https://www.facebook.com/howltcoffee"
					/>
				</li>
				<li>
					<Libs.Atoms.Icon
						type="tw"
						heightPc={heightPc}
						heightSp={heightSp}
						url="https://twitter.com/howltcoffee"
					/>
				</li>
			</ul>
		);
	}
}
export default elementHoc(SnsListHc);
