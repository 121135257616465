import React from "react";
import parse from "html-react-parser";
import * as Config from "./config";
import * as Style from "../styles";
import * as Modules from "../components/modules";
import * as Transition from "../components/Transition";
import * as Common from "../common";
import * as Styles from "../styles";
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n";
import * as Atoms from "../components/atoms";
import * as Libs from "libs";
export const getDeviceSize = () => {
	const breakpoints = [415, 768, 960, 1280];
	const screenW = window.innerWidth;
	let type;
	if (screenW < breakpoints[1]) {
		type = "lessPab";
	} else {
		type = "moreTab";
	}
	return type;
};
export const getTopMargin = () => {
	return { pc: 200, sp: 160 };
};

export const setType = (element) => {
	const node = element ? element : document.querySelector(".project-container");
	const dataset = node.dataset;
	const categoryClass = dataset.categoryslug
		? "category-" + dataset.categoryslug
		: "category-index";
	const cont = document.querySelector("html");
	cont.classList.remove(
		"type-category",
		"type-page",
		"type-post",
		"category-works",
		"category-shop",
		"category-index",
		"category-journal",
		"category-about",
		"category-contact",
		"category-howltcoffee"
	);
	cont.classList.add(`type-${dataset.type}`, `${categoryClass}`);
	// const section = node.querySelectorAll("section:first-child")
	return;
};

export const getBarHeight = () => {
	if (typeof window !== `undefined`) {
		const contH = document.getElementsByTagName("body")[0].clientHeight;
		if (contH !== 0) {
			return contH - window.innerHeight;
		}
		return 0;
	} else {
		return 0;
	}
};

export const getPositionCss = (hPosition, isAbsolute, vPosition) => {
	let positionObj = ``;

	if (hPosition) {
		let hPositionSlug =
			typeof hPosition == "string" || hPosition instanceof String
				? hPosition
				: hPosition.slug;
		if (isAbsolute) {
			if (hPositionSlug === "center" && vPosition === "middle") {
				positionObj = `transform:translate(-50%, -50%); top:50%; left:50%; display: inline-block;`;
			} else if (
				hPositionSlug === "center" &&
				(vPosition === "top" || !vPosition)
			) {
				positionObj = `left: 50%; transform: translateX(-50%) ; display: inline-block;`;
			} else if (
				hPositionSlug === "center" &&
				(vPosition === "bottom" || !vPosition)
			) {
				positionObj = `left: 50%; transform: translateX(-50%); bottom:0; display: inline-block;`;
			} else if (
				hPositionSlug === "right" &&
				(vPosition === "top" || !vPosition)
			) {
				positionObj = `right: 0;`;
			} else if (hPositionSlug === "right" && vPosition === "middle") {
				positionObj = `transform:translateY(-50%); top:50%; right:0;`;
			} else if (hPositionSlug === "right" && vPosition === "bottom") {
				positionObj = `right:0;bottom:0;`;
			} else if (
				hPositionSlug === "left" &&
				(vPosition === "top" || !vPosition)
			) {
				positionObj = `left: 0;`;
			} else if (hPositionSlug === "left" && vPosition === "middle") {
				positionObj = `left:0;transform:translateY(-50%); top:50%;`;
			} else if (hPositionSlug === "left" && vPosition === "bottom") {
				positionObj = `left:0;bottom:0;`;
			}
		} else {
			if (hPositionSlug === "center") {
				positionObj = `margin-left: auto; margin-right: auto;`;
			}
			if (hPositionSlug === "right") {
				positionObj = `margin-left: auto;`;
			}
		}
		/*
    if (!isAbsolute && hPositionSlug === "center") {
      positionObj.left = `margin-left: auto;`
      positionObj.right = `margin-right: auto;`
    } else if (isAbsolute && hPositionSlug === "center") {
      positionObj.left = `left: 50%;`
      positionObj.right = `transform: translateX(-50%);`
    }
    if (hPositionSlug === "right") {
      positionObj.left = `margin-left: auto;`
    }*/
	}
	return positionObj;
};

export const getSpacingCss = (spacing, isAbsolute, dvKey = "pc") => {
	let { marginTop, marginBottom, paddingLeft, paddingRight } = ``;
	const func = { pc: Common.Func.setPcVwValues, sp: Common.Func.setSpVwValues };
	let spacingObj = {};
	if (spacing) {
		if (typeof spacing == "string" || spacing instanceof String) {
			const spacingArr = spacing.split(" ");

			spacingObj.top = spacingArr[0];
			spacingObj.right = spacingArr[1];
			spacingObj.bottom = spacingArr[2];
			spacingObj.left = spacingArr[3];
		} else {
			spacingObj = spacing;
		}

		marginTop = spacingObj.top
			? func[dvKey]("margin-top", spacingObj.top, true)
			: ``;
		marginBottom = spacingObj.bottom
			? func[dvKey]("margin-bottom", spacingObj.bottom, true)
			: ``;
		paddingLeft =
			spacingObj.left && !isAbsolute
				? func[dvKey]("padding-left", spacingObj.left, true)
				: spacingObj.left && isAbsolute
				? func[dvKey]("margin-left", spacingObj.left, true)
				: ``;
		paddingRight =
			spacingObj.right && !isAbsolute
				? func[dvKey]("padding-right", spacingObj.right, true)
				: spacingObj.right && isAbsolute
				? func[dvKey]("margin-right", spacingObj.right, true)
				: ``;
		return {
			top: marginTop,
			right: paddingRight,
			bottom: marginBottom,
			left: paddingLeft,
		};
	} else {
		return {
			top: ``,
			right: ``,
			bottom: ``,
			left: ``,
		};
	}
};

export const getFeatureLayoutCard = (props) => {
	if (props) {
		return props.map((card, i) => {
			const cardType = {
				layout: Common.Func.getCardType(card.layoutRef),
			};
			card.onLoadType = "InitIndexTransition";
			return (
				<cardType.layout
					className={card.onLoadType ? "init-animat-cont" : ""}
					info={card}
				/>
			);
		});
	} else {
		return "";
	}
};
export const getBodyLayoutCard = (props) => {
	if (props) {
		return props.map((card, i) => {
			const cardType = {
				layout: Common.Func.getCardType(card.layoutRef),
			};
			return (
				<section css={Styles.Mixin.containerCss}>
					<cardType.layout info={card} />
				</section>
			);
		});
	} else {
		return "";
	}
};

export const getCardType = (cardTypeString) => {
	if (cardTypeString === "layout-card01") {
		return Modules.LayoutCard01;
	} else if (cardTypeString === "layout-card02") {
		return Modules.LayoutCard02;
	} else if (cardTypeString === "layout-card03") {
		return Modules.LayoutCard03;
	} else if (cardTypeString === "layout-card04") {
		return Modules.LayoutCard04;
	} else if (cardTypeString === "layout-card05") {
		return Modules.LayoutCard05;
	} else if (cardTypeString === "layout-card06") {
		return Modules.LayoutCard06;
	} else if (cardTypeString === "layout-spread-card01") {
		return Modules.LayoutSpreadCard01;
	} else {
		return Modules.LayoutCard02;
	}
};
export const getElementType = (key) => {
	let element = "";
	if (key === "ProductList") {
		element = Modules.ProductList;
	} else if (key === "ImageList") {
		element = Modules.ImageList;
	} else if (key === "MagazineList") {
		element = Modules.RecentPost;
	} else if (key === "RecentPost") {
		element = Modules.RecentPost;
	} else if (key === "HcLogo") {
		element = Atoms.Logo02;
	} else if (key === "SnsList") {
		element = Modules.SnsList;
	} else if (key === "SnsListHc") {
		element = Modules.SnsListHc;
	}
	return {
		element: element,
	};
};

export const getCurentURL = (props) => {
	if (typeof window !== `undefined`) {
		var hostname = window.location.hostname;
		var localhostStrArr = ["localhost", "127.0.0.1"];
		for (var i = 0; i < localhostStrArr.length; i++) {
			if (localhostStrArr[i] === hostname) {
				return Config.localUrl;
			}
		}
		return Config.websiteUrl;
	} else {
		return Config.websiteUrl;
	}
};
export const getLoadTypeFunction = (loadTypeString) => {
	if (loadTypeString) {
		const tmpTyp = loadTypeString;
		if (tmpTyp === "InitIndexTransition") {
			return Transition.InitTransition.InitIndexTransition;
		} else {
			return "";
		}
	}
};

export const parseHtml = (elem) => {
	return parse(elem);
};

const rootRelative = (path) => {
	if (path === undefined) return false;
	const result = path.replace(/\\/g, "/").replace(/^[^/]*\/\/[^/]*/, "");
	return result;
};

const dirctryName = (path) => {
	let result = path.replace(/\\/g, "/").replace(/\/[^/]*$/, "");
	if (result.match("/^[^/]*.[^/.]*$/")) {
		result = "";
	}
	return result;
};

const fullBasename = (path) => {
	let result = "";
	if (path) {
		let paths = path.split("/");

		result = paths.pop();
	}
	return result;
};
const basename = (path) => {
	let result = fullBasename(path).replace("/[?#].*$/g", "");
	return result;
};

const extension = (path) => {
	let result = basename(path).match(/\.([^.]+)$/);
	if (result) {
		result = result[1];
	} else {
		result = "";
	}
	return result;
};
export const getFileName = (url) => {
	//if(!url) return;
	const path = rootRelative(url);
	if (!path) return "index";
	if (path.slice(-1) === "/") {
		url = path.slice(0, -1);
	}

	let extention = extension(url);

	if (extention) {
		url = dirctryName(url);
	}
	//var url = dirctryName(url);

	url = url.substring(url.lastIndexOf("/") + 1, url.length);
	if (url.indexOf(".") !== -1) url = url.substring(0, url.indexOf("."));
	if (url.indexOf("#") !== -1) url = url.replace("#", "");
	if (url.indexOf("?") !== -1) url = url.replace("?", "");
	return url ? url : "index";
};
export const getIsUriName = function(name) {
	let value = null;
	if (typeof window !== `undefined`) {
		value = window.location.href.indexOf(name);
	}
	return value !== -1 ? true : false;
};

export const getDir = function(place, n) {
	return place.pathname.replace(
		new RegExp("(?:\\/+[^\\/]*){0," + ((n || 0) + 1) + "}$"),
		"/"
	);
};
export const getDirName = function(place) {
	let dir = getDir(place);
	let dirlist = dir.split("/");
	return dirlist[dirlist.length - 2];
};
export const getPrevDirName = function(place) {
	let dir = getDir(place);
	let dirlist = dir.split("/");
	return dirlist[dirlist.length - 3];
};

export const toUpperCaseFiest = function(str) {
	if (!str || typeof str !== "string") return str;
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export function isObject(obj) {
	return obj && Object.getPrototypeOf(obj) === Object.prototype;
}

export const mapToObject = (map) =>
	[...map].reduce((l, [k, v]) => Object.assign(l, { [k]: v }), {});
