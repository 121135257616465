/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  const position = prevRouterProps
    ? getSavedScrollPosition(prevRouterProps.location)
    : currentPosition
  //const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  //window.scrollTo(...(currentPosition || [0, 0]))
  window.scrollTo(...position)

  return false
}
