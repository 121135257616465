import React from "react";
import * as Hooks from "../../hooks";

import * as Modules from "./";

import * as Libs from "libs";
export default (props) => {
	const { styles, className } = props;

	const langkey = Libs.Common.Func.getLangKey();
	let edges =
		langkey === "ja"
			? Hooks.AllProductsJa.AllProductsJa()
			: Hooks.AllProductsEn.AllProductsEn();

	const shuffle = ([...array], max) => {
		const len = max ? max : array.length - 1;
		for (let i = len; i >= 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	};
	edges = shuffle(edges).slice(0, 3);
	edges = edges.map(({ node }) => {
		return node;
	});

	return (
		<Libs.Atoms.BlockElement className={className} styles={styles}>
			<Modules.ProductsCategory items={edges} slug={"products"} />
		</Libs.Atoms.BlockElement>
	);
};
