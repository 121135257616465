import React, { PureComponent } from "react";
import * as Modules from "../modules";
import * as Common from "../../common";
import * as Libs from "libs";
class HowltCoffeePage extends PureComponent {
	//--------------------------------------
	//  Styles
	//--------------------------------------

	//--------------------------------------
	//  Scripts
	//--------------------------------------

	render() {
		const { feature, body, lang, className, ...rest } = this.props;

		return (
			<section
				className={className}
				css={Libs.Styles.Mixin.containerCss(true)}
				{...rest}
				data-categoryname={"Howlt Coffee"}
				data-categoryslug={"howltcoffee"}
				data-type={"page"}
			>
				<section ref={this.container}>
					<Libs.Modules.BodyElement
						layoutTypeFunc={Common.Func.getCardType}
						elementTypeFunc={Common.Func.getElementType}
						body={body}
					/>
				</section>
				{/* <section css={ShareListCss}>
          <Modules.ShareList data={{ title: "About Us" }} />
        </section>*/}
				<Modules.RecentPost lang={lang} type={"howltcoffee"}>
					Related Posts
				</Modules.RecentPost>
			</section>
		);
	}
}

export default HowltCoffeePage;
