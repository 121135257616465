import { useStaticQuery, graphql } from "gatsby"

export const ProductsCafeEn = () => {
  const { allContentfulContentShop } = useStaticQuery(
    graphql`
      query ProductsCafeEnQuery {
        allContentfulContentShop(
          filter: {
            node_locale: { eq: "en" }
            tags: { elemMatch: { slug: { eq: "howlt-coffee-product" } } }
          }
        ) {
          edges {
            node {
              slug
              name
              date
              updatedAt(formatString: "MMMM DD, YYYY, h:mm:ss")
              node_locale
              category {
                slug
                name
              }

              thumbnail {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
              }
              tags {
                name
                slug
                group
              }

              name
            }
          }
        }
      }
    `
  )
  return allContentfulContentShop.edges
}
