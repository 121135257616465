import { css } from "@emotion/react";
/*import HowltEot from "../font/Apercu-Regular.eot"
import HowltSvg from "../font/Apercu-Regular.svg"
import HowltWoff from "../font/Apercu-Regular.woff"*/

import HowltItalicWoff from "../font/FeijoaWeb-MediumItalic.woff2";
import HowltItalicEot from "../font/FeijoaWeb-MediumItalic.eot";

import HowltSansWoff from "../font/howlt-sans-reg.woff2";
import HowltSansTtf from "../font/howlt-sans-reg.eot";
import TwmWoff from "../font/twm.woff2";
import TwmEot from "../font/twm.eot";

import DomaineEot from "../font/DomaineTextWeb-Regular.eot";

import DomaineWoff from "../font/DomaineTextWeb-Regular.woff";
export const SanSerif = (props) =>
	css`
		font-family: Howlt, "游ゴシック", "Yu Gothic", YuGothic, Hiragino Sans,
			"ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W1",
			"Hiragino Kaku Gothic ProN W1", "Hiragino Kaku Gothic Pro", "メイリオ",
			Meiryo, Osaka, sans-serif;
	`;
export const Italic = (props) =>
	css`
		font-family: HowltItalic, Domaine, "游明朝", YuMincho,
			"ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
			"ＭＳ 明朝", serif;
		font-style: italic;
	`;
export const Bold = (props) =>
	css`
		font-family: Twm, Howlt, "游ゴシック", "Yu Gothic", YuGothic, Hiragino Sans,
			"ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W1",
			"Hiragino Kaku Gothic ProN W1", "Hiragino Kaku Gothic Pro", "メイリオ",
			Meiryo, Osaka, sans-serif;
		font-weight: 500;
	`;
export const Serif = (props) =>
	css`
		font-family: Domaine, "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
			"Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	`;
export const FontFace = (props) =>
	css`
		@font-face {
			font-family: "HowltItalic";
			src: url(${HowltItalicWoff}) format("woff2"),
				url(${HowltItalicEot}) format("embedded-opentype");
			font-weight: 400;
			font-style: italic;
			font-stretch: normal;
			unicode-range: U + 000d-FB04;
		}
		@font-face {
			font-family: "Twm";
			src: url(${TwmWoff}) format("woff2"),
				url(${TwmEot}) format("embedded-opentype");
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			unicode-range: U + 000d-FB04;
		}
		@font-face {
			font-family: "Howlt";
			src: url(${HowltSansWoff}) format("woff2"),
				url(${HowltSansTtf}) format("embedded-opentype");
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			unicode-range: U + 000d-FB04;
		}
		@font-face {
			font-family: "Domaine";
			src: url(${DomaineWoff}) format("woff2"),
				url(${DomaineEot}) format("embedded-opentype");
			font-style: normal;
			font-weight: 500;
		}
	`;
