import React from "react"
import { css } from "@emotion/react"

export const LogoHowlt = () => (
  <svg css={style} viewBox="0 0 83 20" preserveAspectRatio="xMinYMin meet">
    <use xlinkHref="#howlt-logo" x="0" y="0" />
  </svg>
)
const style = () => css`
  width: 60px;
  height: auto;
  margin-top: 0%;
  fill: #000;
`
export default LogoHowlt
