import React, { PureComponent } from "react";
import { elementHoc } from "libs/atoms/ElementHoc";

import { css } from "@emotion/react";
import * as Libs from "libs";
import * as Common from "../../common";
export class Chart extends PureComponent {
	componentDidMount() {}
	render() {
		let { forwardedref, children, option, ...rest } = this.props;
		const contCss = () => css`
			${Libs.Common.Func.getPcSpVwValue("margin-top", 60, 60)};
		`;

		const tableCss = () => css`
			width: 100%;
			${Libs.Common.Func.getPcSpVwValue("margin-top", 20, 20)};
			td {
				flex: 1 1 0;
				padding-top: 0px;
				padding-bottom: 10px;
				padding-right: 10px;
				padding-left: 10px;
				white-space: nowrap;
				text-align: center;
				width: 25px;
				&:first-child {
					text-align: left;
					padding-left: 0px;
				}
				&:last-child {
					padding-right: 0px;
				}
			}
		`;

		const options = Common.Config.getProductOption(option);
		const header = options.header;
		const labels = options.labels;
		const valueList = options.valueList;
		const note = options.note;
		return (
			<div css={contCss} ref={forwardedref} {...rest}>
				<Libs.Atoms.H4
					styles={{
						fontPc: {
							fontSize: 22,
							fontFace: "sansserif",
							lineHeight: 22,
						},
						fontSp: {
							fontSize: 20,
							fontFace: "sansserif",
							lineHeight: 20,
						},
					}}
				>
					{header}
				</Libs.Atoms.H4>
				<table css={tableCss}>
					<tbody>
						<tr>
							{labels.map((label) => {
								return (
									<td>
										<Libs.Atoms.Span
											styles={{
												fontPc: {
													fontSize: 14,
													fontFace: "sansserif",
													lineHeight: 14,
												},
												fontSp: {
													fontSize: 14,
													fontFace: "sansserif",
													lineHeight: 14,
												},
											}}
										>
											{label}
										</Libs.Atoms.Span>
									</td>
								);
							})}
						</tr>
						{valueList.map((values) => {
							return (
								<tr>
									{values.map((value) => {
										return (
											<td>
												<Libs.Atoms.Span
													styles={{
														fontPc: {
															fontSize: 14,
															fontFace: "sansserif",
															lineHeight: 18,
														},
														fontSp: {
															fontSize: 14,
															fontFace: "sansserif",
															lineHeight: 18,
														},
													}}
												>
													{value}
												</Libs.Atoms.Span>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				<Libs.Atoms.P
					styles={{
						fontPc: {
							fontSize: 14,
							fontFace: "sansserif",
							lineHeight: 14,
						},
						fontSp: {
							fontSize: 14,
							fontFace: "sansserif",
							lineHeight: 14,
						},
						positionPc: { space: "20 0 0 0" },
						positionSp: { space: "20 0 0 0" },
					}}
				>
					{note}
				</Libs.Atoms.P>
			</div>
		);
	}
}

export default elementHoc(Chart);
