import React, { PureComponent } from "react"
import { css } from "@emotion/react"
import * as Transition from "../Transition"
import { connect } from "react-redux"
import * as Libs from "libs"

class Breadcrumb extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
    }
  }
  componentDidMount() {
    this.init = false
    const section = document.querySelectorAll("section:first-child")
    this.dataset = section[0] ? section[0].dataset : null
    this.setState({ ready: true })
  }

  render() {
    const contCss = css`
      ${Libs.Styles.Font.Serif()};
      position: relative;
      ${Libs.Common.Func.getPcSpVwTransform("translateY", 146, 126, true)};
      text-align: center;
      width: 70vw;
      margin-left: auto;
      margin-right: auto;

      a {
        span {
          .on-story & {
            color: #fff;
          }
        }
      }

      transition: transform 1s,
        opacity 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);

      .now-transition & {
        transition: opacity 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        opacity: 0;
      }
      .on-scrollinit & {
        ${Libs.Styles.Mq.moreTab} {
          transform: translateY(130px);
        }

        ${Libs.Styles.Mq.lessPab} {
          transform: translateY(102px);
        }
      }
      .on-pause & {
        opacity: 0;
      }
      ${Libs.Styles.Mq.moreTab} {
        .on-story & {
          transform: translateY(112px);
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        .on-story & {
          transform: translateY(130px);

          text-align: left;
          width: 60vw;
          margin-left: 25px;
        }
      }
    `
    const prevCss = css`
      margin-right: ${Libs.Common.Func.getVwValue(2)}vw;
      display: inline-block;
      .on-story & {
        ${Libs.Styles.Mq.lessPab} {
          display: none;
        }
      }
      &:after {
        content: "|";
        color: #fff;
        margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
      }
    `
    const nextCss = css`
      margin-left: ${Libs.Common.Func.getVwValue(2)}vw;
      display: inline-block;
      .on-story & {
        ${Libs.Styles.Mq.lessPab} {
          display: none;
        }
      }
      &:before {
        content: "|";
        color: #fff;
        margin-right: ${Libs.Common.Func.getVwValue(1)}vw;
      }
    `
    const ulCss = css`
      display: inline-block;
      li {
        ${Libs.Styles.Mq.lessTab} {
          margin-right: ${Libs.Common.Func.getVwValue(1)}vw;
        }

        margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
        &:first-child,
        .type-category &:last-child {
          margin-left: auto;
        }
        display: inline-block;
        text-transform: capitalize;

        &:after {
          .on-story & {
            color: #fff;
          }
          content: ">";
          margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
        }

        .type-category &:last-child {
          transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
          display: block;
          span {
            letter-spacing: -0.02em;
            transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
            pointer-events: none;
            ${Libs.Common.Func.getPcSpVwValue("margin-top", 7, 5)};
            ${Libs.Common.Func.getPcSpVwValue("font-size", 54, 42, true)};
            ${Libs.Common.Func.getPcSpVwValue("line-height", 54, 42, true)};
          }
        }
        .on-story &:last-child {
          display: block;
          margin-top: 5px;
          pointer-events: none;
          span {
            font-size: 20px;
            line-height: 20px;
          }
        }
        .on-scrollinit.type-category &:last-child {
        }
        .on-scroll.type-category &:last-child {
          opacity: 1;
          display: inline-block;
          margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
          span {
            font-size: 15px;
            line-height: 15px;
          }
        }
        &:last-child {
          &:after {
            content: "";
            margin-left: 0;
          }
        }
      }
    `
    const getStyleObject = props => {
      return {
        pcFontSize: 16,
        spFontSize: 16,
        pcLineHeight: 16,
        spLineHeight: 16,
        fontFace: "sansserif",

        letterSpacing: -0.1,
        display: "inline-block",
      }
    }
    let { current, prev, next, category, pageType } = this.props
    let langKey = Libs.Common.Func.getLangKey()
    const getPrevElement = props => {
      const prevSlug = props ? props : prev
      if (prevSlug != null) {
        return (
          <div css={prevCss}>
            <Libs.Atoms.TriggerLink
              transition={
                Libs.Transition.TrigerPageTransition.TrigerPageTransition
              }
              to={`${prevSlug}`}
              onClick={function(event) {
                Transition.TrigerSlideTransition.TrigerSlideTransition(
                  `${prevSlug}`,
                  "prev",
                  event.dispatch
                )
                return false
              }}
            >
              <Libs.Atoms.Span
                className={"bc-button"}
                styles={getStyleObject()}
              >
                PREV
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
          </div>
        )
      } else {
        return ""
      }
    }
    const getNextElement = props => {
      const nextSlug = props ? props : next
      if (nextSlug != null) {
        return (
          <div css={nextCss}>
            <Libs.Atoms.TriggerLink
              transition={
                Libs.Transition.TrigerPageTransition.TrigerPageTransition
              }
              to={`${nextSlug}`}
              onClick={function(event) {
                Transition.TrigerSlideTransition.TrigerSlideTransition(
                  `${nextSlug}`,
                  "next",
                  event.dispatch
                )
                return false
              }}
            >
              <Libs.Atoms.Span
                className={"bc-button"}
                styles={getStyleObject()}
              >
                NEXT
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
          </div>
        )
      } else {
        return ""
      }
    }
    // if (!this.state.ready || !this.dataset) {

    const breadcrumbProps = Libs.Common.Config.breadcrumbProps

    return (
      <div className={"bread-crumb"} css={contCss}>
        {breadcrumbProps &&
        breadcrumbProps.props &&
        breadcrumbProps.props.length > 1 ? (
          <div>
            {breadcrumbProps.paging
              ? getPrevElement(breadcrumbProps.paging.prev)
              : ""}
            <ul css={ulCss}>
              {breadcrumbProps.props.map((bcProp, i) => {
                return (
                  <li>
                    <Libs.Atoms.TriggerLink
                      transition={
                        Libs.Transition.TrigerPageTransition
                          .TrigerPageTransition
                      }
                      to={`${bcProp.url}`}
                    >
                      <Libs.Atoms.Span
                        className={"bc-button"}
                        styles={getStyleObject()}
                      >
                        {bcProp.name}
                      </Libs.Atoms.Span>
                    </Libs.Atoms.TriggerLink>
                  </li>
                )
              })}
            </ul>
            {breadcrumbProps.paging
              ? getNextElement(breadcrumbProps.paging.next)
              : ""}
          </div>
        ) : (
          <></>
        )}
      </div>
    )
    if (breadcrumbProps && breadcrumbProps.props) {
      if (breadcrumbProps.props.length <= 1) return <></>
      const paging = breadcrumbProps.paging

      return (
        <div className={"breadCrumb"} css={contCss}>
          {paging ? getPrevElement(paging.prev) : ""}
          <ul css={ulCss}>
            {breadcrumbProps.props.map((bcProp, i) => {
              return (
                <li>
                  <Libs.Atoms.TriggerLink
                    transition={
                      Transition.TrigerPageTransition.TrigerPageTransition
                    }
                    to={`${bcProp.url}`}
                  >
                    <Libs.Atoms.Span
                      className={"bc-button"}
                      styles={getStyleObject()}
                    >
                      {bcProp.name}
                    </Libs.Atoms.Span>
                  </Libs.Atoms.TriggerLink>
                </li>
              )
            })}
          </ul>
          {paging ? getNextElement(paging.next) : ""}
        </div>
      )
    } else {
      return ""
    }
  }
}

export default connect(state => ({
  current: state.TransitionReducer.current,
  pageType: state.TransitionReducer.pageType,
  category: state.TransitionReducer.category,
  prev: state.TransitionReducer.prev,
  next: state.TransitionReducer.next,
}))(Breadcrumb)
