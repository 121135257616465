import React, { Component } from "react"
import { css } from "@emotion/react"

class Logo extends Component {
  render() {
    return (
      <svg css={style} viewBox="0 0 83 20" preserveAspectRatio="xMinYMin meet">
        <use xlinkHref="#howlt-logo" x="0" y="0" />
      </svg>
    )
  }
}
const style = () => css`
  width: 10vw;
  max-width: 100px;
  min-width: 70px;
  fill: #000;
`
export default Logo
