import React from "react"
import { css } from "@emotion/react"

export const LogoLoworks = () => (
  <svg css={style} viewBox="00 0 112.8 20" preserveAspectRatio="xMinYMin meet">
    <use xlinkHref="#loworks-logo" x="0" y="0" />
  </svg>
)
const style = () => css`
  width: 70px;
  height: auto;
  margin-top: 0%;
  fill: #000;
`
export default LogoLoworks
