import React from "react";
import { css } from "@emotion/react";

import * as Modules from "../modules";

import * as Libs from "libs";
const h3Style = {
	fontPc: {
		fontSize: 14,
		lineHeight: 14,
	},
	fontSp: {
		fontSize: 14,
		lineHeight: 14,
	},
	positionPc: { space: "0 0 7 0" },
	positionSp: { space: "0 0 15 0" },
};
const pStyle = {
	fontPc: {
		fontFace: "sansserif",
		fontSize: 25,
		lineHeight: 30,
		letterSpacing: -0.4,
	},
	fontSp: {
		fontFace: "sansserif",
		fontSize: 25,
		lineHeight: 30,
		letterSpacing: -0.4,
	},
};

const spanCss = {
	fontPc: {
		fontFace: "sansserif",
		fontSize: 14,
		lineHeight: 14,
		letterSpacing: -0.4,
	},
	fontSp: {
		fontFace: "sansserif",
		fontSize: 11,
		lineHeight: 11,
		letterSpacing: -0.4,
	},
};
const contCss = () => css`
	${Libs.Styles.Mq.moreTab} {
		width: 30%;
	}
`;
const leftContCss = () => css`
	${Libs.Styles.Mq.moreTab} {
		width: 40%;
	}
`;
const viemapCss = () => css`
  ${Libs.Styles.Mixin.textAncher()}
    padding-top: ${Libs.Common.Func.getVwValue(0.3)}vw;
    display: inline-block;
  `;
export const InfoAbout = (props) => {
	return (
		<div css={contCss}>
			<Libs.Atoms.H3 styles={h3Style}>Address</Libs.Atoms.H3>
			<Libs.Atoms.P styles={pStyle}>
				535 Carlton Ave
				<br />
				Brooklyn NewYork
			</Libs.Atoms.P>
			<Libs.Atoms.Span styles={spanCss}>
				<a
					css={viemapCss}
					href="https://goo.gl/maps/as8VLteJZRNs4xto6"
					target="_blank"
					rel="noopener noreferrer"
				>
					Google Map
				</a>
			</Libs.Atoms.Span>
		</div>
	);
};

export const InfoAboutHc = (props) => {
	return (
		<div css={contCss}>
			<Libs.Atoms.H3 styles={h3Style}>Address</Libs.Atoms.H3>
			<Libs.Atoms.P styles={pStyle}>
				2-6-11 DAIMYO
				<br />
				CHUO-KU FUKUOKA
			</Libs.Atoms.P>
			<Libs.Atoms.Span styles={spanCss}>
				<a
					css={viemapCss}
					href="https://goo.gl/maps/s77HgHSHN2sWWP2t8"
					target="_blank"
					rel="noopener noreferrer"
				>
					Google Map
				</a>
			</Libs.Atoms.Span>
		</div>
	);
};

export const InfoHour = (props) => {
	return (
		<div css={leftContCss}>
			<Libs.Atoms.H3 styles={h3Style}>Open Hours</Libs.Atoms.H3>
			<Libs.Atoms.P styles={pStyle}>
				Sun: 11 AM - 6 PM
				<br />
				Mon: 11 AM - 6 PM
				<br />
				Tue: 11 AM - 6 PM
				<br />
				Wed: 11 AM - 6 PM
				<br />
				Thu: 11 AM - 6 PM
				<br />
				Fri: 11 AM - 6 PM
				<br />
				Sat: 11 AM - 6 PM
			</Libs.Atoms.P>
			<Libs.Atoms.P
				css={css`
					margin-top: 7px;
				`}
				styles={spanCss}
			>
				Irregular holidays
			</Libs.Atoms.P>
		</div>
	);
};

export const InfoContact = (props) => {
	return (
		<div
			css={css`
				width: 20%;
			`}
		>
			<Libs.Atoms.H3
				css={css`
					white-space: nowrap;
				`}
				styles={h3Style}
			>
				Connect with Us
			</Libs.Atoms.H3>
			<Libs.Atoms.P styles={pStyle}>
				<a href="mailto:info@howlt.com" target="_new">
					info@howlt.com
				</a>
			</Libs.Atoms.P>
			<Modules.SnsList
				styles={{
					width_pc: 7,
					width_sp: 30,
					positionPc: { space: "7 0 0 0" },
					positionSp: { space: "7 0 0 0" },
				}}
			/>
		</div>
	);
};
