import React, { PureComponent } from "react"
import { elementHoc } from "libs/atoms/ElementHoc"

import { css } from "@emotion/react"
import * as Libs from "libs"
export class SnsList extends PureComponent {
  render() {
    let {
      heightPc = 25,
      heightSp = 25,
      className,
      forwardedref,
      children,
      data,

      ...rest
    } = this.props

    const snslistCss = props => css`
      display: flex;
      justify-content: space-between;
      min-width: 94px;

      > * {
        padding-left: 10px;
        &:first-child {
          margin-left: 0px;
        }
      }
    `

    const stylesObj = {}

    return (
      <ul
        ref={forwardedref}
        css={[data.func(stylesObj, data.instance)(), snslistCss()]}
        className={`${className ? className : ""} `}
        {...rest}
      >
        <li>
          <Libs.Atoms.Icon
            type="insta"
            heightPc={heightPc}
            heightSp={heightSp}
            url="https://www.instagram.com/howlt/"
          />
        </li>
        <li>
          <Libs.Atoms.Icon
            type="fb"
            heightPc={heightPc}
            heightSp={heightSp}
            url="https://www.facebook.com/howlt"
          />
        </li>
        <li>
          <Libs.Atoms.Icon
            type="tw"
            heightPc={heightPc}
            heightSp={heightSp}
            url="https://twitter.com/howlt"
          />
        </li>
      </ul>
    )
  }
}

export default elementHoc(SnsList)
