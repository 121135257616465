//MailchimpForm.jsx
import React, { PureComponent } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import TextField from "@material-ui/core/TextField"
import { css } from "@emotion/react"

import * as Libs from "libs"
export class MailChimpForm extends PureComponent {
  constructor() {
    super()
    this.state = { email: "", result: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()

    const langKey = Libs.Common.Func.getLangKey()

    const result = await addToMailchimp(this.state.email, {
      LANG: langKey,
    })

    this.setState({ result: result })
  }
  handleChange = event => {
    this.setState({ email: event.target.value })
  }
  contCss = () => css`
    position: relative;
    text-transform: uppercase;
  `

  textCss = () => css`
    margin-top: 10px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    ${Libs.Styles.Mq.moreTab} {
      display: flex;
      justify-content: center;
      width: 33vw;
    }
    ${Libs.Styles.Mq.lessPab} {
      width: 80vw;
    }
    input {
      padding: 0px;
      padding-top: 10px;
      height: 30px;
      letter-spacing: 1px;
      color: #000;
      ${Libs.Styles.Mq.moreTab} {
        width: 25vw;
      }
      ${Libs.Styles.Mq.lessPab} {
        width: 80vw;
      }
    }
    fieldset {
      border-left: none;
      border-radius: 0px;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 1px dotted;
    }
    .Mui-focused {
      color: #ccc !important;
    }
    .MuiFormLabel-root {
      font-family: "Howlt";
      top: -5px;
      font-size: 14px;
    }
    label + .MuiInput-formControl {
      margin-top: 0px;
    }
  `
  errorCss = () => css`
    position: absolute;
    transform: translateX(-50%);
    text-transform: uppercase;
    left: 50%;
    width: 33vw;
  `
  buttonCss = () => css`
    height: 40px;
    padding: 0px;
    border-radius: 0px;
    text-transform: uppercase;
    ${Libs.Styles.Mq.moreTab} {
      width: 8vw;
    }
    ${Libs.Styles.Mq.lessPab} {
      margin-top: 30px;
      button {
        width: 80vw;
      }
    }
  `
  render() {
    return (
      <div css={this.contCss} {...this.props}>
        <Libs.Atoms.H4
          styles={{
            fontPc: {
              textAlign: "center",
              fontSize: 14,
              lineHeight: 14,
              letterSpacing: 1,
              bold: true,
            },
            fontSp: {
              textAlign: "center",
              fontSize: 14,
              lineHeight: 14,
              letterSpacing: 1,
              bold: true,
            },
            positionPc: { space: "0 0 24 0" },
            positionSp: { space: "0 0 24 0" },
            whiteSpace: "nowrwap",
          }}
        >
          {`Sign up to the Howlt newsletter`}
        </Libs.Atoms.H4>
        <form css={this.textCss} onSubmit={this._handleSubmit}>
          <TextField
            id="outlined-email-input"
            label="YOUR EMAIL"
            name="email"
            autoComplete="email"
            error={"text" === ""}
            onChange={this.handleChange}
          />

          <Libs.Atoms.ButtonElement
            css={this.buttonCss}
            spanStyles={{
              fontPc: {
                fontSize: 14,
                fontFace: "sansserif",
                bold: true,
                letterSpacing: ".5",
              },
              fontSp: {
                fontSize: 16,
                fontFace: "sansserif",
                bold: true,
                letterSpacing: ".5",
              },
            }}
          >
            Submit
          </Libs.Atoms.ButtonElement>
        </form>

        {(this.state.result && this.state.result.result === "success") ||
        (this.state.result && this.state.result.result === "error") ? (
          <Libs.Atoms.P
            css={this.errorCss}
            styles={{
              fontPc: {
                fontSize: 12,
                fontFace: "sansserif",
                letterSpacing: ".5",
              },
              fontSp: {
                fontSize: 12,
                fontFace: "sansserif",
                letterSpacing: ".5",
              },
            }}
          >
            {this.state.result.msg}
          </Libs.Atoms.P>
        ) : null}
      </div>
    )
  }
}
export default MailChimpForm
