import { Component } from "react"
import { connect } from "react-redux"
import { EnterFrameHandler } from "../action"

/**
 * クラス		EnterFrame
 * 継承
 * サブクラス
 */

class EnterFrame extends Component {
  constructor(props) {
    super(props)
    this._framerate = 30
    this.init(this.props.dispatch)

    this.fps = 30.0
    this.frameLength = 30
    this.frame = 0
    this.ready = false
  }
  init = dispatch => {
    this.dispatch = dispatch
  }
  componentDidMount() {
    this.ready = true
  }
  start() {
    if (!this.ready) return false
    const requestAnimationFrame = (function() {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callback) {
          window.setTimeout(callback, 2000.0)
        }
      )
    })()

    const { dispatch } = this.props

    const now =
      window.performance &&
      (performance.now ||
        performance.mozNow ||
        performance.msNow ||
        performance.oNow ||
        performance.webkitNow)

    const getTime = function() {
      return (now && now.call(performance)) || new Date().getTime()
    }
    const startTime = getTime()
    const me = this

    ;(function loop() {
      requestAnimationFrame(loop)

      const lastTime = getTime()
      const prevFrame = me.frame
      me.frame = Math.floor(
        ((lastTime - startTime) / (1000.0 / me.fps)) % me.frameLength
      )
      if (prevFrame !== me.frame) {
        EnterFrameHandler(
          dispatch,
          me.frame,
          window.pageXOffset,
          window.pageYOffset,
          document.getElementsByTagName("html")[0]
        )
      }
    })()
  }

  static get framerate() {
    return this._framerate
  }
  render() {
    return null
  }
}

export default connect(
  null,
  { EnterFrameHandler },
  null,
  { forwardRef: true }
)(EnterFrame)
