import React, { PureComponent } from "react"

import { css } from "@emotion/react"

import * as Common from "../../common"
import * as Libs from "libs"
class AboutPage extends PureComponent {
  //--------------------------------------
  //  Libs.Styles
  //--------------------------------------

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  render() {
    const { feature, body, className, ...rest } = this.props
    const contBaseCss = props => {
      return css`
        width: 88vw;

        border-top: 1px dotted;
        margin-left: auto;
        margin-right: auto;
        ${Libs.Styles.Mq.morePc} {
          margin-top: 100px;
          padding-top: 60px;
        }
        ${Libs.Styles.Mq.lessTab} {
          margin-top: 60px;
          padding-top: 50px;
        }
      `
    }
    const contCss = props => {
      return css`
        ${contBaseCss()}

        ${Libs.Styles.Mq.morePc} {
          display: flex;
          justify-content: space-between;
        }
      `
    }

    const h2Css = props => {
      return css`
        line-height: 100%;
        letter-spacing: 4px;
        ${Libs.Styles.Mq.large} {
          font-size: 58px;
          margin-bottom: 60px;
        }
        ${Libs.Styles.Mq.pc} {
          font-size: 48px;
          margin-bottom: 60px;
        }
        ${Libs.Styles.Mq.lessTab} {
          font-size: 46px;
          letter-spacing: 3px;
          margin-bottom: 30px;
        }
      `
    }
    const h3Css = props => {
      return css`
        margin-top: 40px;
        &:first-child {
          margin-top: 0px;
        }
        ${Libs.Styles.Mq.morePc} {
          font-size: 36px;
          line-height: 100%;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }
        ${Libs.Styles.Mq.lessTab} {
          font-size: 28px;
          line-height: 100%;
          letter-spacing: 1px;
          margin-bottom: 18px;
        }
      `
    }
    const itemCss = props => {
      return css`
        ${Libs.Styles.Mq.large} {
          width: 44vw;
          font-size: 22px;
          line-height: 32px;
        }
        ${Libs.Styles.Mq.pc} {
          width: 44vw;
          font-size: 20px;
          line-height: 30px;
        }
        ${Libs.Styles.Mq.lessTab} {
          width: 88vw;
          font-size: 18px;
          line-height: 24px;
        }
      `
    }
    const itemExhibitionCss = props => {
      return css`
        ${itemCss()}
        display: flex;
        .year {
          margin-right: 20px;
        }
      `
    }
    const clientListCont = props => {
      return css`
        display: flex;

        flex-wrap: wrap;
      `
    }
    const imgCont = props => {
      return css`
        ${Libs.Styles.Mq.large} {
          width: 12.5vw;
        }
        ${Libs.Styles.Mq.pc} {
          width: 17.6vw;
        }
        ${Libs.Styles.Mq.tb} {
          width: 22vw;
        }
        ${Libs.Styles.Mq.lessTab} {
          width: 29.3vw;
        }
      `
    }
    const imgList = [
      { src: "audi.jpg" },
      { src: "citroen.jpg" },
      { src: "coleman.jpg" },
      { src: "docomo.jpg" },
      { src: "ford.jpg" },
      { src: "hitachi.jpg" },
      { src: "lark.jpg" },
      { src: "marlboro.jpg" },
      { src: "mcdonalds.jpg" },
      { src: "nike.jpg" },
      { src: "nintendo.jpg" },
      { src: "pfizer.jpg" },
      { src: "pr01.jpg" },
      { src: "reebok.jpg" },
      { src: "smirnoff.jpg" },
      { src: "sony-music.jpg" },
      { src: "tokyofashionfilm.jpg" },
      { src: "very.jpg" },
      { src: "wacom.jpg" },
      { src: "wwf.jpg" },
    ]
    const h2Style = { fontPc: { fontFace: "sansserif" } }
    return (
      <section
        className={className}
        css={Libs.Styles.Mixin.containerCss(true)}
        {...rest}
        data-categoryname={"About"}
        data-categoryslug={"about"}
        data-type={"page"}
      >
        <section>{Common.Func.getFeatureLayoutCard(feature)}</section>
        <div css={contCss}>
          <Libs.Atoms.H1 css={h2Css} styles={h2Style}>
            EXHIBITION
          </Libs.Atoms.H1>
          <div>
            <Libs.Atoms.H3 css={h3Css} styles={h2Style}>
              Solo Exhibition
            </Libs.Atoms.H3>
            <div css={itemExhibitionCss}>
              <p className="year">2019</p>
              <p>Howlt Popup Store &amp; Coffee / Magasinn Kyoto</p>
            </div>
            <div css={itemExhibitionCss}>
              <p className="year">2018</p>
              <p>HOWLT COFFEE / UltraSuperNew Gallery Tokyo</p>
            </div>
            <div css={itemExhibitionCss}>
              <p className="year">2017</p>
              <p>REPETITION / The Artcomplex Center, Tokyo</p>
            </div>
            <Libs.Atoms.H3 css={h3Css} styles={h2Style}>
              Solo Exhibition
            </Libs.Atoms.H3>
            <div css={itemExhibitionCss}>
              <p className="year">2015</p>
              <p>Gaya art / Asagaya, Tokyo</p>
            </div>
            <div css={itemExhibitionCss}>
              <p className="year">2009</p>
              <p>
                Graniph Japanese Designers project / graniph Harajuku Gallery,
                Tokyo
              </p>
            </div>
            <div css={itemExhibitionCss}>
              <p className="year">2008</p>
              <p>Elshopo x LOWORKS Ehibition / Koenji, Tokyo</p>
            </div>
            <div css={itemExhibitionCss}>
              <p className="year">2006</p>
              <p>JPG2 EXhibition / RAS GalleryDoctor, Barcelona Spain</p>
            </div>
            <div css={itemExhibitionCss}>
              <p className="year">2006</p>
              <p>The new apartment Exhibition / NEW YORK</p>
            </div>
          </div>
        </div>
        <div css={contCss}>
          <Libs.Atoms.H1 css={h2Css} styles={h2Style}>
            PRESS
          </Libs.Atoms.H1>
          <div>
            <p css={itemCss}>
              IdN world (Hong Kong)
              <br />
              Etapes Magazine (France)
              <br />
              Xfuns Magazine(TAIWAN)
              <br />
              Computer Art (UK)
              <br />
              +81 Magazine vol28 (Japan)
              <br />
              gallery - the world’s best graphics-(Hong kong)
              <br />
              MdN magazine (Japan)
              <br />
              Design is kinky
              <br />
              HYPEBEAST
              <br />
              Logo Talks III (Hong Kong)
              <br />
              MdN Designers File(Japan)
              <br />
              MdN Web Design Studio File 2015 (Japan)
              <br />
              MdN Illustrators File (Japan)
              <br />
              Web Designing(Japan)
              <br />
              Openers (Japan)
              <br />
              iCasting -Shopping Design Online (Singapore)
              <br />
              Fukuoka Class (Japan)
              <br />
              JaPan Graphics 2 (Spain)
              <br />
              Web design index 6 (Netherlands)
              <br />
            </p>
          </div>
        </div>
        <div css={contBaseCss}>
          <Libs.Atoms.H1 css={h2Css} styles={h2Style}>
            CLIENTS
          </Libs.Atoms.H1>
          <ul css={clientListCont}>
            {imgList.map((item, i) => (
              <li css={imgCont}>
                <Libs.Atoms.Image src={`${item.src}`} />
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }
}

export default AboutPage
