import React, { PureComponent } from "react";

import * as Common from "../../common";
import * as Libs from "libs";
class ContactPage extends PureComponent {
	//--------------------------------------
	//  Styles
	//--------------------------------------

	//--------------------------------------
	//  Scripts
	//--------------------------------------

	render() {
		const { feature, className, body, ...rest } = this.props;

		return (
			<section
				className={className}
				ref={this.container}
				css={Libs.Styles.Mixin.containerCss(true)}
				{...rest}
				data-categoryname={"Contact"}
				data-categoryslug={"contact"}
				data-type={"page"}
			>
				<section>
					<Libs.Modules.BodyElement
						layoutTypeFunc={Common.Func.getCardType}
						elementTypeFunc={Common.Func.getElementType}
						body={body}
					/>
				</section>
			</section>
		);
	}
}

export default ContactPage;
