import { css } from "@emotion/react";

export let SanSerifFont = "Messina";
export let SerifFont = "Domaine";
export let BoldFont = "Twm";
export let ItalicFont = "HowltItalic";
export const setSanSerifFont = (font) => {
	SanSerifFont = font;
};
export const setBoldFont = (font) => {
	BoldFont = font;
};

export const setSerifFont = (font) => {
	SerifFont = font;
};

export const setItalicFont = (font) => {
	ItalicFont = font;
};
export const SanSerif = (props) =>
	css`
		font-family: ${SanSerifFont}, "ヒラギノ角ゴ Pro W3",
			"Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	`;
export const Italic = (props) =>
	css`
		font-family: ${ItalicFont}, ${SerifFont}, "游明朝", YuMincho,
			"ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
			"ＭＳ 明朝", serif;
		font-style: italic;
	`;
export const Bold = (props) =>
	css`
		font-family: ${BoldFont}, ${SanSerifFont}, "ヒラギノ角ゴ Pro W3",
			"Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
		font-weight: 500;
	`;
export const Serif = (props) =>
	css`
		font-family: ${SerifFont}, "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
			"Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	`;
