import React, { Component } from "react"
import { css } from "@emotion/react"
import * as Styles from "../../styles"
class Symbol extends Component {
  render() {
    return (
      <svg css={style} viewBox="0 0 97 110" preserveAspectRatio="xMinYMin meet">
        <use xlinkHref="#symbol" x="0" y="0" />
      </svg>
    )
  }
}
const style = () => css`
  height: 5vw;
  max-height: 60px;
  .on-scroll & {
    display: none;
  }
  ${Styles.Mq.lessPab} {
    height: 11vw;
    min-height: 40px;
    margin-top: -11px;
  }
`
export default Symbol
