import React, { useEffect, useRef } from "react"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { useDispatch, useSelector } from "react-redux"

export default props => {
  const { images, cssObj } = props

  let len = useRef(0)
  let num = useRef(0)
  // console.log("els --- 4")
  const els = useRef([])
  images.forEach((_, i) => {
    els.current[i] = React.createRef()
    len.current = i
  })
  let seconds = useSelector(state => state.EnterFrameReducer.seconds2)
  const imgCss = props => {
    return css`
      height: 100%;
      &.hidden {
        display: none;
      }
    `
  }

  useEffect(() => {
    if (num.current === len.current + 1) {
      num.current = 0
    }
    els.current.map((prop, i) => {
      if (prop.current !== null) {
        prop.current.classList.add("hidden")
      }
    })
    const elm = els.current[num.current]
    if (elm && elm.current) {
      elm.current.classList.remove("hidden")
    }
    num.current++
    // console.log("els --- 3", num.current, len.current)
  }, [seconds])

  return (
    <>
      {images.map((props, i) => {
        return (
          <div
            css={imgCss}
            ref={els.current[i]}
            className={"img-cont img-element"}
          >
            <Img css={imgCss} fluid={props.fluid} alt={props.title} />
          </div>
        )
      })}
    </>
  )
}
