import React, { PureComponent } from "react";
import { css } from "@emotion/react";
import * as Modules from "../modules";
import * as Common from "../../common";
import ScrollEase from "libs/ScrollEase";
import * as Libs from "libs";
class IndexPage extends PureComponent {
	//--------------------------------------
	//  Styles
	//--------------------------------------

	//--------------------------------------
	//  Scripts
	//--------------------------------------
	constructor(props) {
		super(props);
		this.container = React.createRef();
	}
	componentDidMount() {
		ScrollEase.WrappedComponent.container = this.container.current;
	}

	render() {
		const { feature, body, className, ...rest } = this.props;
		const sectionCss = () => {
			return css`
				${Libs.Common.Func.getPcSpVwValue(
					"margin-top",
					Libs.Common.Func.getTopMargin().pc,
					Libs.Common.Func.getTopMargin().sp,
					true
				)};
			`;
		};
		return (
			<>
				<section
					className={className}
					ref={this.container}
					{...rest}
					data-categoryname={"Index"}
					data-categoryslug={"index"}
					data-type={"page"}
				>
					<section css={sectionCss}>
						<Libs.Modules.BodyElement
							layoutTypeFunc={Common.Func.getCardType}
							elementTypeFunc={Common.Func.getElementType}
							body={body}
						/>
					</section>

					<Modules.InstaList />
				</section>
			</>
		);
	}
}

export default IndexPage;
