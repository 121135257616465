import React, { PureComponent } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import reducer from "libs/redux/reducers/index";
import { createStore } from "redux";
import { Provider } from "react-redux";
import * as Libs from "libs";
import * as Styles from "../styles";

import * as Common from "../common";
import * as Modules from "../components/modules";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import * as Transition from "../components/Transition";
import WindowResize from "libs/redux/event/WindowResize";
import EnterFrame from "libs/redux/event/EnterFrame";
import { getCurrentLangKey } from "ptz-i18n";
import { IntlProvider } from "react-intl";
import ContextProvider from "../provider/ContextProvider";
const Container = styled.div`
	align-items: center;
	justify-content: center;
`;
Libs.Common.Config.setLangInfo(["ja", "en"], "ja");

const store = createStore(reducer);
const style = () => {
	return css`
		opacity: 0;
	`;
};
let current = "Index";
class Layout extends PureComponent {
	static get current() {
		return current;
	}
	static set current(value) {
		current = value;
	}
	constructor(props) {
		super(props);

		this.container = React.createRef();
		this.enterframe = React.createRef();
		if (typeof window !== `undefined`) {
			const location = window.location;
			const url = location.pathname;

			const { langs, defaultLangKey } = Libs.Common.Config.languages;
			this.langKey = getCurrentLangKey(langs, defaultLangKey, url);
			this.homeLink = `/${this.langKey}/`;

			this.langsMenu = Libs.Common.Func.getLangInfo();
			Libs.Common.Config.setDispatcher(store.dispatch);
			Libs.Common.Config.setInitTransition(
				Transition.InitTransition.InitTransition
			);
		}
	}
	componentDidMount() {
		const target = document.querySelector(".tl-wrapper");
		this.enterframe.start();
		//
		target.setAttribute("style", "visibility:visible");
		this.container.current.setAttribute("style", "opacity:1");
		if (typeof window !== `undefined`) {
			//document.body.scrollTop = document.documentElement.scrollTop = 0
		}

		/*
    const me = this
    const tl = new TimelineMax({
      onComplete: function() {
        me.enterframe.start()

      },
    })
    tl.set(target, { visibility: "visible", ease: Power2.easeOut })
    tl.to(this.container.current, 1, {
      opacity: 1,
    })*/

		// Transition.InitTransition.InitTransition(store.dispatch)
	}
	render() {
		const { children } = this.props;

		return (
			<IntlProvider locale={"en-US"} defaultLocale="ja">
				<ContextProvider>
					<Provider store={store}>
						<Container ref={this.container} css={style}>
							<Libs.Styles.Reset />
							<Styles.SiteStyle.StoryStyle />
							<Libs.Styles.Global.GlobalStyle />
							<WindowResize />
							<EnterFrame
								dispatch={store.dispatch}
								ref={(el) => {
									this.enterframe = el;
								}}
							></EnterFrame>

							{children}
							<Header />
							<Footer langs={this.langsMenu} />
							<Modules.Loader />
							<Libs.Atoms.Overlay />
						</Container>
					</Provider>
				</ContextProvider>
			</IntlProvider>
		);
	}
}

export default Layout;
