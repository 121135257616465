import React, { PureComponent } from "react";
import * as Common from "../common";
import * as LibsAtoms from "./";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";

import { renderRichText } from "gatsby-source-contentful/rich-text";

import { textHoc } from "./TextHoc";
import Img from "gatsby-image";
export class RichText extends PureComponent {
	render() {
		let { forwardedref, children, data, ...rest } = this.props;

		const Bold = ({ children }) => (
			<LibsAtoms.Span
				className="rt-content"
				styles={{
					fontPc: { bold: true },
					fontSp: { bold: true },
					display: "inline-block",
				}}
			>
				{children}
			</LibsAtoms.Span>
		);
		let pcFontSize = data.instance.styles
			? data.instance.styles.pcFontSize
			: null;
		let spFontSize = data.instance.styles
			? data.instance.styles.spFontSize
			: null;
		pcFontSize = pcFontSize ? pcFontSize : null;
		spFontSize = spFontSize ? spFontSize : null;
		const Text = ({ children }) => {
			if (children.length === 1 && children[0][1] === "") {
				return "";
			} else {
				const style = {
					fontPc: {
						fontFace: "sansserif",
						fontSize: 18,
						lineHeight: 26,
					},
					fontSp: {
						fontFace: "sansserif",
						fontSize: 18,
						lineHeight: 26,
					},
				};

				let fontPc = Object.assign(style.fontPc, data.instance.styles.fontPc);

				let fontSp = Object.assign(style.fontSp, data.instance.styles.fontSp);
				if (data.instance.styles.stylesJson) {
					fontPc = Object.assign(
						fontPc,
						data.instance.styles.stylesJson.fontPc
					);
					fontSp = Object.assign(
						fontSp,
						data.instance.styles.stylesJson.fontSp
					);
				}

				return (
					<LibsAtoms.P
						className="rt-content rt-textfield"
						styles={{ fontPc, fontSp }}
					>
						{children}
					</LibsAtoms.P>
				);
			}
		};
		const website_url = Common.Config.websiteUrl;
		const localhost = Common.Config.localUrl;
		const stylesObj = {
			pcLineHeight: pcFontSize * 1.7,
			spLineHeight: spFontSize * 1.7,
			fontFace: "serif",
			display: "block",
		};

		if (pcFontSize) stylesObj.pcFontSize = pcFontSize;
		const options = {
			renderMark: {
				[MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
			},
			renderText: (text) =>
				text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),

			renderNode: {
				[BLOCKS.PARAGRAPH]: (node, next) => {
					return next(node.content).replace("\n", "<br/>");
				},
			},

			renderNode: {
				[BLOCKS.PARAGRAPH]: (node, children) => {
					return <Text>{children}</Text>;
				},
				[BLOCKS.EMBEDDED_ASSET]: (node) => {
					return (
						<div className="rt-content img-cont rt-asset">
							<Img fluid={node.data.target.fluid} />
						</div>
					);
				},
				[BLOCKS.EMBEDDED_ENTRY]: (node) => {
					if (node.data.target.internal.type === "ContentfulElementButton") {
						return (
							<LibsAtoms.ButtonElement
								className="rt-content rt-button"
								styles={node.data.target}
								spanStyles={node.data.target.stylesJson}
							>
								{node.data.target.name}
							</LibsAtoms.ButtonElement>
						);
					} else if (
						node.data.target.internal.type === "ContentfulElementImage"
					) {
						return (
							<LibsAtoms.ImageElement
								className={"rt-content"}
								styles={node.data.target}
							/>
						);
					} else if (
						node.data.target.internal.type === "ContentfulElementLink"
					) {
						return (
							<LibsAtoms.LinkElement
								icon={node.data.target.icon}
								className={`rt-content rt-link ${node.data.target.class}`}
								styles={node.data.target}
							>
								{node.data.target.label}
							</LibsAtoms.LinkElement>
						);
					}
				},
				[BLOCKS.HEADING_1]: (node, children) => {
					return (
						<LibsAtoms.H1
							className="rt-content rt-header"
							styles={{
								spacePc: "0 0 20 0",
								spaceSp: "0 0 14 0",
							}}
						>
							{children}
						</LibsAtoms.H1>
					);
				},
				[BLOCKS.HEADING_2]: (node, children) => {
					return (
						<LibsAtoms.H2
							className="rt-content rt-header"
							styles={{
								spacePc: "0 0 20 0",

								spaceSp: "0 0 14 0",
							}}
						>
							{children}
						</LibsAtoms.H2>
					);
				},
				[BLOCKS.HEADING_3]: (node, children) => {
					return (
						<LibsAtoms.H3
							className="rt-content rt-header"
							styles={{
								spacePc: "0 0 20 0",

								spaceSp: "0 0 14 0",
							}}
						>
							{children}
						</LibsAtoms.H3>
					);
				},
				[BLOCKS.HEADING_4]: (node, children) => {
					return (
						<LibsAtoms.H4
							className="rt-content rt-header"
							styles={{
								fontPc: {
									fontSize: 18,
									lineHeight: 18,
									fontFace: "sansserif",
									bold: true,
								},
								spacePc: "0 0 20 0",
								fontSp: {
									fontSize: 18,
									lineHeight: 18,
									fontFace: "sansserif",
									bold: true,
								},
								spaceSp: "0 0 14 0",
							}}
						>
							{children}
						</LibsAtoms.H4>
					);
				},
				[INLINES.ENTRY_HYPERLINK]: (node) => {},
				[INLINES.ASSET_HYPERLINK]: (node) => {},
				[INLINES.EMBEDDED_ENTRY]: (node) => {},
				[INLINES.HYPERLINK]: (node) => {
					if (
						node.data.uri.startsWith(website_url) ||
						node.data.uri.startsWith(localhost)
					) {
						return (
							<a
								href={node.data.uri}
								className={"textAnchor"}
								target={`_self`}
								rel={`${
									node.data.uri.startsWith(website_url)
										? ""
										: "noopener noreferrer"
								}`}
							>
								{node.content[0].value}
							</a>
							/*
              <LibsAtoms.TriggerLink
                transition={
                  Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={node.data.uri.slice(
                  node.data.uri.indexOf(website_url) + website_url.length
                )}
                className={"textAnchor"}
              >
                {node.content[0].value}
              </LibsAtoms.TriggerLink>*/
						);
					} else {
						return (
							<a
								href={node.data.uri}
								className={"textAnchor"}
								target={`${
									node.data.uri.startsWith(website_url) ? "_self" : "_blank"
								}`}
								rel={`${
									node.data.uri.startsWith(website_url)
										? ""
										: "noopener noreferrer"
								}`}
							>
								{node.content[0].value}
							</a>
						);
					}
				},
			},
		};
		return (
			<div
				ref={forwardedref}
				css={data.func(stylesObj, data.instance)}
				{...rest}
			>
				{renderRichText(children, options)}
			</div>
		);
	}
}

export default textHoc(RichText);
