import React, { Component } from "react"
import { css } from "@emotion/react"

class InitialLoader extends Component {
  constructor(props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount() {}

  render() {
    const containerCss = () => {
      return css`
        .spinner {
          width: 140px;
          height: 12px;
          position: fixed;
          top: 50%;
          left: 50%;
          margin-left: -70px;
          margin-top: -20px;
          text-align: center;
          font-size: 10px;
          opacity: 0;
          z-index: 100000;
          transition: opacity 0.3s ease-out;
          -moz-transform: scale(0.6);
          -o-transform: scale(0.6);
          -ms-transform: scale(0.6);
          -webkit-transform: scale(0.6);
          transform: scale(0.6);
          .init-load &,
          .page-load & {
            opacity: 1;
            transition: opacity 0.3s ease-out;
          }
          div:not(.o) {
            background-color: #000;
            position: absolute;
          }
          .h1,
          .h2,
          .w1,
          .w2,
          .w3,
          .l1,
          .t1 {
            height: 100%;
            width: 7px;
            .init-load &,
            .page-load & {
              -webkit-animation: sk-stretchdelay 0.5s infinite ease-in-out;
              animation: sk-stretchdelay 0.5s infinite ease-in-out;
              transform-origin: center bottom;
            }
          }
          .h2 {
            left: 20px;
            .init-load &,
            .page-load & {
              transition-delay: -1.1s;
            }
          }
          .o {
            position: absolute;
            left: 34px;

            width: 24px;
            height: 12px;
            .loading_o-path {
              fill: #000;
            }
          }
          .w1 {
            left: 65px;
            .init-load &,
            .page-load & {
              -webkit-animation-delay: -1s;
              animation-delay: -1s;
            }
          }
          .w2 {
            left: 79px;
            .init-load &,
            .page-load & {
              -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
            }
          }
          .w3 {
            left: 93px;
            .init-load &,
            .page-load & {
              -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s;
            }
          }
          .l1 {
            left: 107px;
            .init-load &,
            .page-load & {
              -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
            }
          }
          .t1 {
            width: 8px;
            left: 125px;
            .init-load &,
            .page-load & {
              -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s;
            }
          }
          .t2 {
            width: 17px;
            height: 5px;
            left: 121px;
            top: 7px;
          }
        }

        @-webkit-keyframes sk-stretchdelay {
          0%,
          40%,
          100% {
            -webkit-transform: scaleY(1);
          }
          20% {
            -webkit-transform: scaleY(1.4);
          }
        }

        @keyframes sk-stretchdelay {
          0%,
          40%,
          100% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
          }
          20% {
            transform: scaleY(1.4);
            -webkit-transform: scaleY(1.4);
          }
        }
      `
    }

    return (
      <div css={containerCss}>
        <div className="spinner">
          <div className="h1"></div>
          <div className="h2"></div>
          <div className="o">
            <svg
              id="loading_o"
              data-name="loading_o"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 12"
            >
              <path
                className="loading_o-path"
                d="M7.4,12c0-3.8,2.5-5.6,5.1-5.6s5.1,1.8,5.1,5.6h6.9a11.67,11.67,0,0,0-12-12A11.73,11.73,0,0,0,.5,12H7.4Z"
                transform="translate(-0.5 0)"
              />
            </svg>
          </div>
          <div className="w1"></div>
          <div className="w2"></div>
          <div className="w3"></div>
          <div className="l1"></div>
          <div className="t1"></div>
          <div className="t2"></div>
        </div>
      </div>
    )
  }
}

export default InitialLoader
