import React from "react"
import { css } from "@emotion/react"
import * as Hooks from "../../hooks"
import * as Modules from "./"
import * as Organisms from "../organisms"
import * as Libs from "libs"
export default props => {
  const langkey = Libs.Common.Func.getLangKey()
  const type = props.type
  let worksEdges = []
  let productEdges = []
  let jouranlEdges = []
  if (type === "howltcoffee") {
    jouranlEdges =
      langkey === "ja"
        ? Hooks.useJaJournalCafeData.useJaJournalCafeData()
        : Hooks.useEnJournalCafeDataEn.useEnJournalCafeDataEn()
    productEdges =
      langkey === "ja"
        ? Hooks.ProductsCafeJa.ProductsCafeJa()
        : Hooks.ProductsCafeEn.ProductsCafeEn()
  } else {
    worksEdges =
      langkey === "ja"
        ? Hooks.useWorksListData.useJaWorksData()
        : Hooks.useWorksListDataEn.useEnWorksData()

    productEdges =
      langkey === "ja"
        ? Hooks.AllProductsJa.AllProductsJa()
        : Hooks.AllProductsEn.AllProductsEn()
    jouranlEdges =
      langkey === "ja"
        ? Hooks.useJournalListData.useJaJournalData()
        : Hooks.useJournalListDataEn.useEnJournalData()
  }

  let edges = worksEdges
    .concat(productEdges, jouranlEdges)
    .sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date)
    })
  /*
  const shuffle = ([...array], max) => {
    const len = max ? max : array.length - 1
    for (let i = len; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }*/
  edges = edges.slice(0, 5)
  //edges = shuffle(edges).slice(0, 4)
  edges = edges.map(({ node }) => {
    return node
  })
  const sectionCss = css`
    ${Libs.Common.Func.getPcSpVwValue("margin-top", 120, 90)};
  `
  const HeaderAddLinkCss = css`
    ${Libs.Common.Func.getPcSpVwValue("margin-bottom", 45, 35)};
  `

  return (
    <section css={sectionCss}>
      <Modules.HeaderAddLink lang={langkey} css={HeaderAddLinkCss}>
        {props.children ? props.children : "Recent Posts"}
      </Modules.HeaderAddLink>
      <Organisms.CategoryPage items={edges} type={"recentpost"} />
    </section>
  )
}
